var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "LBL0001174",
              tableId: "assessImpr",
              columns: _vm.gridImpr.columns,
              data: _vm.riskReduce.imprs,
              merge: _vm.gridImpr.merge,
              gridHeight: _vm.setheight,
            },
            on: { linkClick: _vm.linkClick },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "picture"
                      ? [
                          _c("q-icon", {
                            staticClass: "text-primary",
                            staticStyle: {
                              "font-size": "30px",
                              cursor: "pointer",
                            },
                            attrs: { name: "image" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openImprPicture(props)
                              },
                            },
                          }),
                        ]
                      : col.name === "checkItemName"
                      ? [
                          props.row.checkItemId &&
                          props.row.ramCheckItemClassCd !== "RCIC000015"
                            ? [
                                _c("q-btn", {
                                  staticClass: "tableinnerBtn",
                                  attrs: {
                                    flat: "",
                                    align: "left",
                                    color: "blue-6",
                                    label: props.row[col.name],
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openItem(props.row)
                                    },
                                  },
                                }),
                              ]
                            : [_vm._v(" " + _vm._s(props.row[col.name]) + " ")],
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }